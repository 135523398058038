<template>
  <v-container fluid class="pa-5">
    <v-btn
      outlined
      color="primary"
      class="mb-6"
      :to="'/external/compliance'"
      elevation="2"
      style="border-radius: 30px; padding: 12px 24px;"
    >
      <v-icon left>mdi-arrow-left</v-icon>
      Voltar para Compliance
    </v-btn>

    <h1 class="text-h4 font-weight-bold mb-6 text-center" style="color: #3f51b5;">
      {{ categoryTitle }}
    </h1>

    <v-row>
      <v-col v-for="article in paginatedArticles" :key="article.id" cols="12" md="4" sm="6">
        <v-card
          height="500"
          class="mx-auto d-flex flex-column justify-space-between"
          color="indigo lighten-5"
          style="border-radius: 15px; transition: transform 0.3s; box-shadow: 0 4px 10px rgba(0,0,0,0.1);"
          @mouseover="hover = true"
          @mouseleave="hover = false"
        >
          <v-img
            class="white--text align-end"
            height="200px"
            :src="article.featuredImage"
            style="border-top-left-radius: 15px; border-top-right-radius: 15px;"
          />
          <v-card-subtitle class="text-uppercase pt-5 px-6 pb-0" style="color: #616161;">
            {{ article.createdAt | dateFormatted }}
          </v-card-subtitle>
          <v-card-text class="py-0 px-2 text-card">
            <v-card-title class="text-truncate" style="font-weight: 600;">
              {{ article.headline }}
            </v-card-title>
            <v-card-subtitle class="text-truncate" style="color: #757575;">
              {{ article.caption }}
            </v-card-subtitle>
          </v-card-text>
          <v-card-actions>
            <v-btn
              outlined
              color="secondary"
              :to="`/external/read-compliance/${article.slug}`"
              style="border-radius: 20px; padding: 5px 20px;"
            >
              Ver mais
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-pagination
        v-model="currentPage"
        :length="pageCount"
        color="primary"
      ></v-pagination>
    </v-row>
  </v-container>
</template>

<script>
import { findActiveExternalCompliances } from "../../services/compliance-service";
import { AllCategory } from "../../services/compliance-category-service";

export default {
  name: "CategoryArticlesExternal",
  data() {
    return {
      articles: [],
      filteredArticles: [],
      categoryTitle: "",
      hover: false,
      currentPage: 1,
      itemsPerPage: 6,
    };
  },
  computed: {
    pageCount() {
      return Math.ceil(this.filteredArticles.length / this.itemsPerPage);
    },
    paginatedArticles() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredArticles.slice(start, end);
    },
  },
  async created() {
    this.articles = await findActiveExternalCompliances();

  const categoryId = parseInt(this.$route.params.id);
  this.filteredArticles = this.articles
    .filter((article) => article.category.id === categoryId)
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)); 

  const categories = await AllCategory();
  const category = categories.find((cat) => cat.id === categoryId);
  this.categoryTitle = category ? category.title : "Categoria não encontrada";
  },
};
</script>

<style scoped>
.text-card {
  color: rgba(0, 0, 0, 0.7);
}

.v-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
}
</style>
